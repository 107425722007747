import React, {useEffect, useState} from 'react';
import axios from 'axios';
import moment from 'moment';
import qs from 'qs';
import classnames from 'classnames';

import {createUrl} from '../../../../util/formatters';
import ContentCard from '../../components/content-card/content-card';
import PhotoPreview from '../../components/photo-preview/photo-preview';
import allRecipeIcons from '../../../../images/react-icons/recipes/all-recipe-icons';

import './recipe.css'

let ErrorMessage = ({error}) => {
    return (
        <ContentCard className="recipe-errorMessage">
            <h3>Error</h3>
            <div>{error}</div>
        </ContentCard>
    );
};

let RecipeActions = ({cookbook, recipeId, history, externalLink}) => {
    let [showWakeLock, setShowWakeLock] = useState(false);
    let [wakeLock, setWakeLock] = useState(false);

    let checkWakeLockPermission = async () => {
        let result = await navigator.permissions.query({name:'screen-wake-lock'});
        setShowWakeLock(['granted', 'prompt'].includes(result.state));
        return () => {
            if (wakeLock) return toggleWakeLock();
        }
    };
    useEffect(() => {checkWakeLockPermission()}, []);

    let toggleWakeLock = async () => {
        try {
            if (wakeLock) {
                await wakeLock.release();
                setWakeLock();
            } else {
                setWakeLock(await navigator.wakeLock.request("screen"));
            }
        } catch (error) {
            console.error(error);
        }
    };
    return (
        <div className="recipe-recipeActions">
            {showWakeLock && (
                <button
                    className={classnames('recipe-wakeLockButton', {'recipe-wakeLockButton--disable': wakeLock})}
                    onClick={toggleWakeLock}
                >{wakeLock ? 'Disable' : 'Enable'} Wake Lock</button>
            )}
            {externalLink && (
                <button
                    className="recipe-viewExternalRecipeButton"
                    onClick={() => window.open(externalLink, '_blank')}
                >View External Recipe</button>
            )}
            <button
                className="recipe-editRecipeButton"
                onClick={() => history.push(`/sous-chef/cookbooks/${cookbook.id}/recipes/${recipeId}/edit`)}
            >Edit Recipe</button>
        </div>
    );
};

let Labels = ({tags}) => {
    if (!tags.length) return null;
    let labels = tags.join(', ');
    return <div className="recipe-labels"><span>Labels:</span> {labels}</div>;
};

let TIME_UNITS_DISPLAY = {minutes: 'minute(s)', hours: 'hour(s)'};
let TimeEstimates = ({timeEstimates}) => {
    if (!timeEstimates.length) return null;
    let timeEstimateComponents = timeEstimates.map(({id, description, duration, units}) => <li key={id}>{description}: {duration} {TIME_UNITS_DISPLAY[units] || units}</li>);
    return (
        <div className="recipe-timeEstimates">
            <h3>Time Estimates</h3>
            <ul>{timeEstimateComponents}</ul>
        </div>
    );
};

let Notes = ({notes}) => {
    if (!notes) return null;
    return (
        <div className="recipe-notes">
            <div className="recipe-notesHeader">Author's Note</div>
            <div>{notes}</div>
        </div>
    );
};

let HardcopyPhotos = ({hardcopyPhotos, recipe}) => {
    let {instructions, ingredients} = recipe;
    let showPhotosDefault = !instructions?.length || !ingredients?.length;
    let [showPhotos, setShowPhotos] = useState(showPhotosDefault);
    if (!hardcopyPhotos.length) return null;
    let content;
    if (showPhotos) {
        let photoPreviews = hardcopyPhotos.map((hardcopyPhoto, index) => <PhotoPreview photo={hardcopyPhoto} key={index} className="recipe-hardcopyPhoto" />);
        content = <div className="recipe-hardcopyPhotos">{photoPreviews}</div>;
    } else {
        content = <button onClick={() => setShowPhotos(true)} className="recipe-showPhotosButton">Show Photo(s)</button>;
    }
    return (
        <div className="recipe-hardcopyRecipe">
            <h3>Hardcopy Recipe</h3>
            {content}
        </div>
    );
};

let Ingredients = ({ingredients, instructions, selectedInstruction: selectedInstructionId}) => {
    if (!ingredients.length) return null;
    let selectedInstruction = instructions.find(instruction => instruction.id === selectedInstructionId);
    let selectedIngredients = selectedInstruction?.ingredients || [];
    let ingredientComponents = ingredients.map(({id, description, quantity}) => {
        let isSelected = selectedIngredients.includes(id);
        return (
            <li className={classnames('recipe-ingredient', {'recipe-ingredient--selected': isSelected})} key={id}>
                <span className="recipe-ingredientQuantity">{quantity}</span> {description}
            </li>
        );
    });
    let photos = ingredients
        .filter(({photos = []}) => !!photos.length)
        .map(({id, photos}) => <PhotoPreview key={id} photo={photos[0]} className="recipe-ingredientPhoto"/>);
    return (
        <div className="recipe-ingredients">
            <h3>Ingredients</h3>
            <ul>{ingredientComponents}</ul>
            <div className="recipe-ingredientPhotos">{photos}</div>
        </div>
    );
};

let Instructions = ({instructions, location, otherQueryParams, selectedInstruction, setSelectedInstruction}) => {
    if (!instructions.length) return null;
    let instructionComponents = instructions.map(({id, description, photos = []}, index) => {
        let photo = photos[0] && <PhotoPreview photo={photos[0]} className="recipe-instructionPhoto"/>;
        let onClick = () => {
            let queryParamString;
            if (id === selectedInstruction) {
                setSelectedInstruction();
                queryParamString = qs.stringify(otherQueryParams);
                if (queryParamString.length) queryParamString = '?' + queryParamString;
            } else {
                setSelectedInstruction(id);
                queryParamString = '?' + qs.stringify({...otherQueryParams, selectedInstruction: id});
            }
            window.history.replaceState(null, null, location.pathname + queryParamString);
        };
        return (
            <div
                onClick={onClick}
                className={classnames('recipe-instruction', {'recipe-instruction--selected': id === selectedInstruction})}
                key={id}
            >
                <div className="recipe-instructionNumber">{index + 1}.</div>
                <div>
                    <div>{description}</div>
                    {photo}
                </div>
            </div>
        );
    });
    return (
        <div className="recipe-instructions">
            <h3>Instructions</h3>
            <div>{instructionComponents}</div>
        </div>
    );
};

let PendingDeletionWarning = ({reloadRecipe, reloadCookbook, recipe, cookbook}) => {
    let {pendingDeletionAsOf: recipePendingDeletionAsOf} = recipe;
    let {pendingDeletionAsOf: cookbookPendingDeletionAsOf} = cookbook;
    let pendingDeletionAsOf = cookbookPendingDeletionAsOf || recipePendingDeletionAsOf;
    if (!pendingDeletionAsOf) return null;
    let recipeWarning = !!recipePendingDeletionAsOf;
    let cookbookWarning = !!cookbookPendingDeletionAsOf;
    if (cookbookPendingDeletionAsOf && recipePendingDeletionAsOf) {
        pendingDeletionAsOf = moment(cookbookPendingDeletionAsOf).isBefore(recipePendingDeletionAsOf) ? cookbookPendingDeletionAsOf : recipePendingDeletionAsOf;
    }
    let daysUntilDeletion = Math.max(moment(pendingDeletionAsOf).add(30, 'days').diff(moment(), 'days'), 0);
    let s = daysUntilDeletion !== 1 && 's';
    let deletedItems = [];
    if (recipeWarning) deletedItems.push('the recipe');
    if (cookbookWarning) deletedItems.push('the cookbook');
    let onRestore = async () => {
        await axios.put(createUrl(`/cookbooks/${cookbook.id}/recipes/${recipe.id}/restore`));
        await reloadRecipe();
        await reloadCookbook();
    };
    return (
        <ContentCard className="recipe-redWarning">
            <div>
                <span>Warning:</span> This recipe will be permanently deleted in <span>{daysUntilDeletion} day{s}</span>.
                Restore {deletedItems.join(' and ')} to prevent it from being deleted.
            </div>
            {recipeWarning && <button onClick={onRestore} type="button">Restore&nbsp;Recipe</button>}
        </ContentCard>
    )
};

let GeneralErrorMessages = () => {
    let {errorMessage} = qs.parse(window.location.search.slice(1));
    if (!errorMessage) return null;
    return (
        <ContentCard className="recipe-redWarning">
            <div>
                <span>Error:</span> {errorMessage}
            </div>
        </ContentCard>
    )
};

export default function Recipe ({match, history, location, updateTitleBar, cookbook, reloadCookbook}) {
    let {recipeId} = match.params;
    let {selectedInstruction: selectedInstructionQueryParam, ...otherQueryParams} = qs.parse(location.search.slice(1));
    let [selectedInstruction, setSelectedInstruction] = useState(selectedInstructionQueryParam);
    let [data, setData] = useState();
    let [error, setError] = useState();

    let loadData = async () => {
        try {
            let recipe = (await axios.get(createUrl(`/cookbooks/${cookbook.id}/recipes/${recipeId}`))).data;
            updateTitleBar(recipe.name, {isPrivate: recipe.isPrivate});
            setData({recipe});
        } catch (error) {
            console.error(error.response);
            updateTitleBar('Unknown Recipe');
            if (error.response?.status === 404) {
                setError('Recipe not found');
            } else {
                setError('Unable to load recipe');
            }
        }
    };
    useEffect(() => {
        loadData();
        return () => updateTitleBar();
    }, []);

    if (error) return <ErrorMessage error={error} />;
    if (!data) return null;
    let {recipe} = data;
    let {photos = [], icon, originalRecipe = {}, instructions = [], ingredients = [], timeEstimates = [], notes, tags = []} = recipe;
    let photo = photos[0];
    let hardcopyPhotos = originalRecipe.photos || [];
    let RecipeIcon = allRecipeIcons[icon];
    return (
        <div className="recipe">
            <RecipeActions history={history} cookbook={cookbook} recipeId={recipeId} externalLink={originalRecipe.link} />
            <PendingDeletionWarning
                reloadRecipe={loadData}
                reloadCookbook={reloadCookbook}
                recipe={recipe}
                cookbook={cookbook}
            />
            <GeneralErrorMessages />
            <ContentCard className="recipe-contentCard">
                {photo && <PhotoPreview photo={photo} className="recipe-photo" />}
                {!photo && RecipeIcon && <RecipeIcon className="recipe-icon" />}
                <Labels tags={tags} />
                <TimeEstimates timeEstimates={timeEstimates} />
                <Notes notes={notes} />
                <Ingredients ingredients={ingredients} instructions={instructions} selectedInstruction={selectedInstruction} />
                <Instructions instructions={instructions} location={location} otherQueryParams={otherQueryParams} selectedInstruction={selectedInstruction} setSelectedInstruction={setSelectedInstruction} />
                <HardcopyPhotos hardcopyPhotos={hardcopyPhotos} recipe={recipe} />
            </ContentCard>
        </div>
    );
}
