export let actionsNamespace = 'AggieLaCroix/';

let {AL_ENV, LOCAL, LOCAL_IP} = process.env;
export let DOMAIN = LOCAL === 'true' ? `http://${LOCAL_IP}:3000` : 'https://api.aggielacroix.com';

export let SORT_DISPLAY = {
    origin: {
        createdAt: 'Date Created',
        priority: 'Priority',
        label: 'Alphabetical',
        price: 'Price'
    },
    reference: {
        claimedAt: 'Date Claimed',
        owner: 'Recipient',
        label: 'Alphabetical',
        price: 'Price'
    }
};

export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const INTERSTITIAL_KEYS = {
    DASHBOARD: 'DASHBOARD',
    ALL_GIFTS: 'ALL_GIFTS',
    COMPLETE_GIFTS: 'COMPLETE_GIFTS',
    HUB_LIST_VIEW: 'HUB_LIST_VIEW',
    HUB_MESSAGES_VIEW: 'HUB_MESSAGES_VIEW',
    LIST: 'LIST',
    GIFT_RECTIFICATION_MODAL_LOAD: 'GIFT_RECTIFICATION_MODAL_LOAD',
    GIFT_RECTIFICATION_MODAL_SUBMIT: 'GIFT_RECTIFICATION_MODAL_SUBMIT'
};

/**
 * primaryColor
 * primaryShade
 * bookmarkColor
 * bookmarkShade
 */
export let COOKBOOK_COLORS = {
    RED: {
        primaryColor: '#D32436',
        primaryShade: '#8e242a',
        bookmarkColor: '#393939',
        bookmarkShade: '#232323',
    },
    ORANGE: {
        primaryColor: '#ff913e',
        primaryShade: '#cb6c2f',
    },
    YELLOW: {
        primaryColor: '#ede723',
        primaryShade: '#bf8751'
    },
    LIGHT_GREEN: {
        primaryColor: '#1abc9c',
        primaryShade: '#168a70'
    },
    DARK_GREEN: {
        primaryColor: '#008d65',
        primaryShade: '#004a25',
    },
    LIGHT_BLUE: {
        primaryColor: '#4E9BD4',
        primaryShade: '#3769A5'
    },
    DARK_BLUE: {
        primaryColor: '#4059c8',
        primaryShade: '#1e398f',
    },
    PURPLE: {
        primaryColor: '#8d3e86',
        primaryShade: '#451385',
    },
    BROWN: {
        primaryColor: '#825d41',
        primaryShade: '#4f3227'
    },
    GREY: {
        primaryColor: '#696969',
        primaryShade: '#3c3c3c'
    },
    BLACK: {
        primaryColor: '#323232',
        primaryShade: '#161616',
    },
};

export const DRAGGABLE_ITEMS = {
    INSTRUCTION: 'instruction',
    INGREDIENT: 'ingredient',
    TIME_ESTIMATE: 'timeEstimate'
};

export const LOCAL_STORAGE_KEYS = {
    SOUS_CHEF: {
        PREFER_SIDEBAR_HIDDEN: 'sousChef:preferSideBarHidden'
    }
};
