import React, {useState, useEffect} from 'react';
import {Route, Switch, Link} from 'react-router-dom';
import qs from 'qs';
import {connect} from 'react-redux';
import axios from 'axios';

import Dashboard from './dashboard/dashboard';
import Cookbooks from './cookbooks/cookbooks';
import Cookbook from './cookbooks/cookbook/cookbook';
import CookbookForm from './cookbooks/cookbook-form/cookbook-form';
import {login as loginAction} from '../../redux/users/users-actions';
import {screenIsNoSmallerThan, BREAKPOINT_NAMES} from '../../util/breakpoints';
import RenderRoute from './components/render-route/render-route';
import {createUrl} from '../../util/formatters';
import ErrorMessenger from './components/error-messenger/error-messenger';
import RecentlyDeleted from './recently-deleted/recently-deleted';
import Search from './search/search';
import {LOCAL_STORAGE_KEYS} from '../../util/constants';

import './sous-chef.css';

export function SousChef({history, location, authenticated, login, activeBreakpoint}) {
    let [defaultSideBarVisible, setDefaultSideBarVisible] = useState(false);
    let [preferSideBarHidden, setPreferSideBarHidden] = useState(
        localStorage.getItem(LOCAL_STORAGE_KEYS.SOUS_CHEF.PREFER_SIDEBAR_HIDDEN) === 'true'
    );
    let [sideBarVisible, setSideBarVisible] = useState(false);
    let [errorMessage, setErrorMessage] = useState();
    let [data, setData] = useState();

    let loadData = async () => {
        let jwtToken = sessionStorage.getItem('jwtToken');
        if (!authenticated) {
            if (!jwtToken) {
                let queryParams = qs.stringify({redirectTo: location.pathname + location.search});
                history.push(`/gift-tags/login?` + queryParams);
                return;
            } else {
                login(jwtToken);
            }
        }
        try {
            // This call is to both determine if we have a valid, non-expired token as well as check access to Sous Chef
            let hasSousChefAccess = (await axios.get(createUrl('/accesses/name/SOUS_CHEF'))).data.hasAccess;
            if (!hasSousChefAccess) history.push('/gift-tags');
            setData({hasSousChefAccess});
        } catch (error) {
            console.error(error);
            if (error.response?.status === 401) {
                let queryParams = qs.stringify({expiredToken: true, redirectTo: location.pathname + location.search});
                history.push(`/gift-tags/login?` + queryParams);
            }
            setErrorMessage('Oops, something went wrong... Try again later.');
        }
    };
    useEffect(() => {loadData()}, []);

    useEffect(() => {
        let desiredVisibility = screenIsNoSmallerThan(activeBreakpoint, BREAKPOINT_NAMES.desktopLg);
        setSideBarVisible(desiredVisibility && !preferSideBarHidden);
        setDefaultSideBarVisible(desiredVisibility);
    }, [activeBreakpoint]);

    let toggleSidebar = () => {
        setSideBarVisible(!sideBarVisible);
        setPreferSideBarHidden(sideBarVisible);
        localStorage.setItem(LOCAL_STORAGE_KEYS.SOUS_CHEF.PREFER_SIDEBAR_HIDDEN, sideBarVisible.toString());
    };

    let SideBar = () => {
        if (!sideBarVisible) return null;
        return (
            <div className="sousChef-sideBar">
                <h1 onClick={() => setSideBarVisible(defaultSideBarVisible)}>
                    <button className="sousChef-sideBarTitleButton" onClick={() => history.push('/sous-chef')}>
                        Sous-Chef
                    </button>
                </h1>
                <div className="sousChef-sideBarLinks" onClick={() => setSideBarVisible(defaultSideBarVisible)}>
                    <Link className="sousChef-sideBarLink" to="/sous-chef">Dashboard</Link>
                    <Link className="sousChef-sideBarLink" to="/sous-chef/search">Search</Link>
                    <Link className="sousChef-sideBarLink" to="/sous-chef/cookbooks">Cookbooks</Link>
                    {/*<Link className="sousChef-sideBarLink" to="/sous-chef/recently-viewed">Recently Viewed</Link>*/}
                    {/*<Link className="sousChef-sideBarLink" to="/sous-chef/favorites">Favorites</Link>*/}
                    <Link className="sousChef-sideBarLink" to="/sous-chef/recently-deleted">Recently Deleted</Link>
                    <Link className="sousChef-sideBarLink" to="/gift-tags">Open Gift Tags</Link>
                    <Link className="sousChef-sideBarLink" to="/gift-tags/logout">Logout</Link>
                </div>
            </div>
        );
    };

    if (!authenticated || !data?.hasSousChefAccess) return null;
    let additionalRouteProps = {toggleSidebar};
    return (
        <div className="sousChef">
            <SideBar />
            <div className="sousChef-content">
                <ErrorMessenger>{errorMessage}</ErrorMessenger>
                {!errorMessage && (
                    <Switch>
                        <Route path="/sous-chef/cookbooks/new" render={RenderRoute(CookbookForm, additionalRouteProps)} />
                        <Route path="/sous-chef/cookbooks/:cookbookId" render={RenderRoute(Cookbook, additionalRouteProps)} />
                        <Route path="/sous-chef/cookbooks" render={RenderRoute(Cookbooks, additionalRouteProps)} />
                        <Route path="/sous-chef/search" render={RenderRoute(Search, additionalRouteProps)} />
                        <Route path="/sous-chef/recently-deleted" render={RenderRoute(RecentlyDeleted, additionalRouteProps)} />
                        <Route path="/sous-chef" exact render={RenderRoute(Dashboard, additionalRouteProps)} />
                    </Switch>
                )}
                {sideBarVisible && <div className="sousChef-sideBarMask" onClick={() => setSideBarVisible(defaultSideBarVisible)} />}
            </div>
        </div>
    )
}

let mapStateToProps = state => ({
    authenticated: state.users.get('authenticated')
});

let actions = {
    login: loginAction
};

export default connect(mapStateToProps, actions)(SousChef);
